import React from 'react';
import Content from '/components/content/Content';
import Footer from '/components/footer/Footer';

/**
 * You're a clever coder aren't ya!
 * this is our precious...
 */
const Pony = () => (
  <>
    <Content>
      🦄 Neigh 🦄
      <iframe
        title="Pony"
        style={{ border: 'none', marginTop: '16px' }}
        src="https://www.youtube.com/embed/4uOKGPd3scY"
        width="100%"
        height="768px"
        allow="autoplay"
      />
    </Content>
    <Footer />
  </>
);

export default Pony;
